<link rel="stylesheet" [href]="commonUrl + 'themes/common.css' | safe" />

<svg id="svgViewableArea" preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute" 
  [attr.height]="config.reportArea.h"
  [attr.width]="config.reportArea.w" 
  class="svgfull reportCommon" *ngIf="showReport">

  <g [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'" 
     id="reportArea"  
     [attr.transform]="reportTranslationObj.translateReportAttribute">

    <text 
     [attr.x]="config.base.reportTitle.x" 
     [attr.y]="config.base.reportTitle.y"
     [attr.font-size]="config.base.reportTitle.size" 
     [attr.class]="config.base.reportTitle.style" 
     text-anchor="middle">
     {{config.options.title}}
   </text>

    <image 
      [attr.href]="commonUrl + 'images/Main_Circle.svg'" 
      [attr.x]="config.base.mainCircle.x"
      [attr.y]="config.base.mainCircle.y" 
      [attr.height]="config.base.mainCircle.height"
      [attr.width]="config.base.mainCircle.width" />

    <text fill="white" text-anchor="middle">
      <tspan [attr.x]="config.base.mainCircle.text1x" [attr.y]="config.base.mainCircle.text1y"
        [attr.font-size]="config.base.mainCircle.text1size" class="svgtext">{{mainCircleText}}</tspan>
      <tspan [attr.x]="config.base.mainCircle.text2x" [attr.y]="config.base.mainCircle.text2y"
        [attr.font-size]="config.base.mainCircle.text2size" class="svgtext">{{totalFamilyMembers}}</tspan>
    </text>
    
    <g *ngFor="let position of circlePositions">

      <image 
        [attr.x]="position.lineStartX - 1.5 * config.base.centerLine.offsetX " 
        [attr.y]="position.lineStartY - 1.5 * config.base.centerLine.offsetY" 
        [attr.width]="config.base.centerLine.width"
        [attr.height]="config.base.centerLine.height" 
        [attr.href]="commonUrl + 'images/Center_Line.svg'"
        [attr.transform]="'rotate(' + position.lineAngle + ',' + position.lineStartX + ',' + position.lineStartY + ')'" />

      <image 
        [attr.x]="(position.secondaryCircleX - config.base.secondaryCircle.height/2)" 
        [attr.y]="(position.secondaryCircleY - config.base.secondaryCircle.height/2)" 
        [attr.height]="config.base.secondaryCircle.height"
        [attr.width]="config.base.secondaryCircle.width" 
        [attr.href]="commonUrl + 'images/Sub_Circle.svg'" />

        <image 
          [attr.x]="(position.secondaryCircleX - config.base.tertiaryCircle.offsetX )" 
          [attr.y]="(position.secondaryCircleY - config.base.tertiaryCircle.offsetY )" 
          [attr.width]="config.base.curvedLine.width"
          [attr.height]="config.base.curvedLine.height"
          [attr.href]="commonUrl + 'images/' + position.tertiaryCircleImage"
          [attr.transform]="'rotate(' + (position.isRightSide ? 180 : 0) + ',' + position.secondaryCircleX + ',' + position.secondaryCircleY + ')'" />

         <g *ngFor="let tertiarycircle of position.tertiaryCirclesData">

          <g *ngFor="let tertiaryData of tertiarycircle.tertiaryCircleLabels; let i = index">
          
            <text>
              <tspan 
                [attr.x]="(position.secondaryCircleX + tertiarycircle.tertiaryCircleLabelXPosition)" 
                [attr.y]="(position.secondaryCircleY + tertiarycircle.tertiaryCircleLabelYPositions[i])" 
                [attr.font-size]="config.base.tertiaryText.fontsize1" 
                class="svgtext">
                {{tertiaryData.key}}</tspan>

              <tspan 
                [attr.x]="(position.secondaryCircleX +  tertiarycircle.tertiaryCircleLabelXPosition + config.base.tertiaryText.gap)" 
                [attr.y]="(position.secondaryCircleY + tertiarycircle.tertiaryCircleLabelYPositions[i])" 
                [attr.font-size]="config.base.tertiaryText.fontsize2" 
                class="svgtextbold">
                {{tertiaryData.value}}</tspan>
              </text>

          </g> 
        </g>

      <text 
        fill="white" 
        [attr.font-size]="config.base.secondaryCircle.fontsize"
        class="svgtext"
        text-anchor="middle" 
        alignment-baseline="middle">

        <tspan *ngIf="position.secondaryCircleLabel.length === 1" [attr.x]="position.secondaryCircleX" [attr.y]="position.secondaryCircleY + 10">{{ position.secondaryCircleLabel[0] }}</tspan>

        <tspan *ngIf="position.secondaryCircleLabel.length === 2" [attr.x]="position.secondaryCircleX" [attr.y]="position.secondaryCircleY - 30">{{ position.secondaryCircleLabel[0] }}</tspan>
        <tspan *ngIf="position.secondaryCircleLabel.length === 2" [attr.x]="position.secondaryCircleX" [attr.y]="position.secondaryCircleY">{{ position.secondaryCircleLabel[1] }}</tspan>

        <tspan *ngIf="position.secondaryCircleLabel.length === 3" [attr.x]="position.secondaryCircleX" [attr.y]="position.secondaryCircleY - 20">{{ position.secondaryCircleLabel[0] }}</tspan>
        <tspan *ngIf="position.secondaryCircleLabel.length === 3" [attr.x]="position.secondaryCircleX" [attr.y]="position.secondaryCircleY + 10" >{{ position.secondaryCircleLabel[1] }}</tspan>
        <tspan *ngIf="position.secondaryCircleLabel.length === 3" [attr.x]="position.secondaryCircleX" [attr.y]="position.secondaryCircleY + 40">{{ position.secondaryCircleLabel[2] }}</tspan>
      </text>
    </g>

  </g>
</svg>