<div class="ft-form">
    <div class="content">
        <div class="ft-form-row row-align">
            <div class="ft-form-row col1-v100 row-margin">
                <span class="title titlle-span">{{ "ph_search_member" | translate }}</span>
                <!-- Filter Drop down -->
                <div class="filter">
                    <div class="filterItem-header" (click)="showFilterDropdown()">
                        <div class="item-text-header">{{ selectfilterText  | translate }}</div>
                        <div><img class="icon" src="assets/images/icons/dropdown-arrow.svg" alt="dropicon"></div>
                    </div>          
                    <div *ngIf="isFilterDropDownOpen" class="dropDown">
                        <div class="filterItem" *ngFor="let opt of sortOptionList">
                            <div class="filterItem" [class.item-selected]="opt.id == selectedfilterType" (click)="sortData(opt.id)">
                                <div class="item-text">{{ opt.name | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Filter Drop down -->
            </div>
        </div>
        <span class="label-grey-base-normal label-wrapper">
            <div class="label-caption"></div>
        </span> 
        <div class="ft-form-row">
            <div class="ft-form-row col1-v100">
                <div class="ft-form-input">
                    <div class="icon-input-container">
                        <input class ="icon-input icon-input-expanded" placeholder="{{'ph_search_all_media' | translate}}" type="text" [(ngModel)]="searchPhrase" (keyup)="searchOnKeyUp($event.target.value);">
                        <div class="icon-input-wrap">                      
                            <img src="/assets/images/icons/search.svg"  class="icon-image" alt="serachicon">                       
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
        <div class="ft-form-row">       
            <div class="member-list-container">
                <div class="member-list">
                    <div class="no-member-item" *ngIf="individulaList.length === 0">
                        <p class="no-results">{{'lbl_no_results_found' | translate}}</p>
                    </div>
                    <div class="member-item" *ngFor="let individul of individulaList" [ngClass]="{'member-selected': selectedMemberId === individul.id}" (click)="selectMember(individul.id)">
                        <p *ngIf="selectfilterColumn === 'givenName' || selectfilterColumn === 'surName'" class="p-blank">
                            {{ individul.displayName }}
                        </p>
                        <p *ngIf="selectfilterColumn === 'id'" class="p-blank">
                            {{ individul.id }}. {{ individul.displayName }}
                        </p>
                        <p *ngIf="selectfilterColumn === 'gender'" [ngClass]="{'p-men': individul.gender === 'M', 'p-women': individul.gender === 'F', 'p-blank': individul.gender === 'U'}">
                            {{ lastSelectfilterColumn === 'id' ? individul.id + '. ' : '' }}{{ individul.displayName }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn" type="submit" form="ngFormPlace" [ngClass]="selectedMemberId ? 'ft-btn-active' : 'ft-btn-inactive'" [disabled]="!selectedMemberId" (click)="loadRootMember()">{{ "lbl_set_root_member" | translate}}</button>
      <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>  
    </div>
  </div>