import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { IndividualsComponent } from '../../individual/individuals/individuals.component';
import { RelationshipComponent } from '../../individual/relationship/relationship.component';
import { MultimediaMainComponent } from '../../multimedia/multimedia-main/multimedia-main.component';
import { PlaceMainComponent } from '../../place/place-main/place-main.component';
import { SourceCitationMainComponent } from '../../source-citation/source-citation-main/source-citation-main.component';
import { SourceMainComponent } from '../../source/source-main/source-main.component';
import { WitnessMainComponent } from '../../witness/witness-main/witness-main.component';
import { DialogBaseComponent } from '../dialog-base/dialog-base.component';
import { SunchartOptionPopupComponent } from 'src/app/components/reports/sunchart/sunchart-option-popup/sunchart-option-popup.component';
import { ReportPublishOptionComponent } from '../../report-publish-option/report-publish-option.component';
import { FamilyProjectOptionMainComponent } from 'src/app/components/familyprojectmanager/family-project-option-main/family-project-option-main.component';
import { PreferenceMainComponent } from 'src/app/components/preferences/preference-main/preference-main.component';
import { AddNewProjectMainComponent } from 'src/app/components/familyprojectmanager/add-new-project-main/add-new-project-main.component';
import { ReportOptionsPopupComponent } from 'src/app/components/reports/common/reportOptions/reportOptionsPopup/report-options-popup.component';
import { IndividualMainComponent } from '../../individual/individual-main/individual-main.component';
import { AboutusMainComponent } from 'src/app/components/aboutus/aboutus-main/aboutus-main.component';
import { ReportsMenuComponent } from 'src/app/components/mainmenu/reports-menu/reports-menu.component';
import { VariationsMainComponent } from 'src/app/components/mainmenu/reports-menu/variations-main/variations-main.component';
import { SearchMainComponent } from '../../search/search-main/search-main.component';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, AfterViewInit {

  // Get all level hosts
  @ViewChild('content1', { read: ViewContainerRef, static: false }) public content1: ViewContainerRef;
  @ViewChild('content2', { read: ViewContainerRef, static: false }) public content2: ViewContainerRef;
  @ViewChild('content3', { read: ViewContainerRef, static: false }) public content3: ViewContainerRef;
  @ViewChild('content4', { read: ViewContainerRef, static: false }) public content4: ViewContainerRef;
  @ViewChild('content5', { read: ViewContainerRef, static: false }) public content5: ViewContainerRef;

  // Opacity layer visibility
  isModalVisible: boolean = false;
  
  // Starting width ( can set to 97% )
  initialWidth: string = "43%";

  componentMap: Map<string, any>

  levelVisibility: boolean[];
  levelHostComponent: ViewContainerRef[];

  lastComponent: DialogBaseComponent;

  constructor(private dialogService: DialogService, private resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    // Define all dialog components here
    this.componentMap = new Map<string, any>();
    this.componentMap.set("Relationship", RelationshipComponent);
    this.componentMap.set("Place", PlaceMainComponent);
    this.componentMap.set("Multimedia", MultimediaMainComponent);
    this.componentMap.set("Source", SourceMainComponent);
    this.componentMap.set("Witness", WitnessMainComponent);
    this.componentMap.set("SourceCitation", SourceCitationMainComponent);
    this.componentMap.set("SunchartOptionPopup",SunchartOptionPopupComponent );
    this.componentMap.set("ReportOptionPopup", ReportOptionsPopupComponent)
    this.componentMap.set("ReportPublishOption", ReportPublishOptionComponent);
    this.componentMap.set("FamilyProjectOptionMain", FamilyProjectOptionMainComponent);
    this.componentMap.set("PreferenceMain", PreferenceMainComponent);
    this.componentMap.set("AddNewProjectMain",AddNewProjectMainComponent);
    this.componentMap.set("IndividualMain",IndividualMainComponent);
    this.componentMap.set("AboutUs", AboutusMainComponent);
    this.componentMap.set("ReportsMenu", ReportsMenuComponent);
    this.componentMap.set("VariationsMain", VariationsMainComponent);
    this.componentMap.set("SearchMain", SearchMainComponent);
    

    this.levelVisibility = [false, false, false, false, false];

    // Listen to dialog service open
    this.dialogService.onOpen.subscribe(openRequest => {
      
      // only 5 levels allowed
      if (openRequest.level > 4) return;
      this.isModalVisible = openRequest.level >= 0 ? true : false;
      this.initialWidth   = this.dialogService.getWidth();

      // Get level host and clear it
      let hostlevel = this.levelHostComponent[openRequest.level];
      hostlevel.clear();

      // Load component
      let component = this.componentMap.get(openRequest.name);
      this.loadComponent(openRequest.name, component, hostlevel, openRequest.data);

      // Set level visibility
      this.levelVisibility[openRequest.level] = true;
    });

    // Listen to close 
    this.dialogService.onClose.subscribe(closeRequest => {
      console.log("close requet", closeRequest);
      this.levelVisibility[closeRequest.level] = false;
      this.isModalVisible = closeRequest.level > 0 ? true : false;
      console.log(this.levelVisibility);
    });
  }

  // Load level hosts after view init
  ngAfterViewInit(): void {
    this.levelHostComponent = [this.content1, this.content2, this.content3, this.content4, this.content5];
  }

  getActiveLevel() {
    return this.levelVisibility.lastIndexOf(true);
  }

  // Dynamically load the components
  loadComponent(name: string, componentClass: any, host: ViewContainerRef, data: any) {
    const factory     = this.resolver.resolveComponentFactory<DialogBaseComponent>(componentClass);
    let componentRef  = host.createComponent<DialogBaseComponent>(factory);
    componentRef.instance.data = data;

    this.lastComponent = componentRef.instance
  }

  closePopup(){
    // When opening as a list disable overlay close
    if(this.dialogService.getWidth() != "100%")
      this.dialogService.close(this.lastComponent?.lastResponseInfo);
  }
}
