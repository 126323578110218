import { Injectable } from '@angular/core';
import { IndexedDBHelper } from './indexed-db-helper';
import { StorageService } from './storage.service';
import { IndividualBaseViewModel } from 'src/app/common/models/IndividualViewModel';

@Injectable({
  providedIn: 'root',
})
export class ProjectIdbService  extends StorageService<any> {
  constructor(indexedDBHelper: IndexedDBHelper) 
  {
    super(indexedDBHelper, 'project');
  }

  public getTableName(projectId: string): string {
    throw new Error('Method not implemented.');
  }

  async setProject(projectId: string): Promise<void> {
    await this.indexedDBHelper.setCurrentProject(projectId);
  }

  async getProject(): Promise<string | null> {
    return await this.indexedDBHelper.getCurrentProject();
  }
  
}