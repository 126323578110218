import { Component, OnInit, ElementRef } from '@angular/core';
import { BaseComponent } from '../../BaseReport/base/base.component';
import { LoadingService } from "src/app/services/UI/loading.service";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { MessageDialogService } from 'src/app/components/common/message-dialog/services/message-dialog.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { CirclePosition } from './circle-positions';
import { CircleData } from './circle-data';
import { TERTIARY_CIRCLE_PREFIX } from './circle-data';
import { NotifierEvents } from 'src/app/common/enums/enums';
import { ReportOptions } from '../../common/reportOptions/reportOptions';
import { ReportFieldOption } from '../../common/reportOptions/reportFieldOption';
import { Observable } from "rxjs";
import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-factsheet',
  templateUrl: './factsheet.component.html',
  styleUrls: ['./factsheet.component.scss'],
  providers: [DecimalPipe]
})
export class FactsheetComponent extends BaseComponent implements OnInit {

  expectedUpdates = [NotifierEvents.RootMemberChanged];
  reportOptions;
  defaultHeight = 0;
  public totalFamilyMembers: number;
  public mainCircleText: string;
  public circlePositions: CirclePosition[] = [];
  public circleData: CircleData = new CircleData();

  public rightSide: boolean;
  public tertiaryCircleImage: string = 'Con-3.svg';

  constructor(
    private loadingService: LoadingService,
    protected factSheettHostElement: ElementRef,
    protected translateHandler: TranslateHandler,
    protected individualApiService: IndividualApiService,
    protected messageDialogService: MessageDialogService,
    protected notifierService: NotifierV2Service,
    protected dialogService: DialogService,
    protected familyProjectService: FamilyProjectApiService,
    private decimalPipe: DecimalPipe
  ) {
    super(factSheettHostElement, dialogService, notifierService, translateHandler, messageDialogService, individualApiService);
  }

  ngOnInit(): void {
    if (this.config) {
      this.chartBasics = {
        name: this.config.name,
        assetUrl: this.commonUrl,
      };
      this.initReport();
    }
  }

  notify() {
    this.initReport();
  }

  initReport() {
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.config.options.title = this.translateHandler.translate("fact_sheet.lbl_title", [this.rootMember.DisplayName]);
    this.mainCircleText = this.translateHandler.translate("fact_sheet.lbl_main_circle_text");
    this.initReportOptions();
    this.initReportData();
  }

  initReportOptions() {
    this.reportOptions = new ReportOptions();
    this.reportOptions.reportName = "fact_sheet.lbl_name"
    this.reportOptions.fieldOptions.push(new ReportFieldOption("title", "title", "input", [], this.config.options.title));
  }

  initReportData(customTitle = null) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.familyProjectService.getFamilyStats().
      subscribe(res => {
        this.totalFamilyMembers = res.data.totalFamilyMembers;
        this.setCircleDataFromResponse(res.data);
        this.circlePositions = [];
        this.calculateCirclePositions();
        this.showReport = true  
        this.reportZoomToExtend()  
        this.reportTitle = customTitle == null ? this.translateHandler.translate("fact_sheet.lbl_title",[this.rootMember.DisplayName]) : customTitle;  
      },
      error => {
        this.loadingService.hide(processId);
        console.error('Error loading family stats:', error);   
        return this.showError(
          "lbl_error_heading",
          "factsheetReport.err_no_factsheet_reports",
          "factsheetReport.err_generating_factsheet_report_message"
        ) 
      }).add(() => {
        this.loadingService.hide(processId);
      });
  }

  showError(title: string, info: string, prompt: string): Observable<any> {
    return this.messageDialogService.openError(
      this.translateHandler.translate(title),
      this.translateHandler.translate(info),
      prompt == null ? null : this.translateHandler.translate(prompt)
    );
  }

  setCircleDataFromResponse(data: any) {
    this.circleData['fact_sheet.lbl_second_circle_gender_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.male`]: data?.genderStats?.male || 0,
      [`${TERTIARY_CIRCLE_PREFIX}.female`]: data?.genderStats?.female || 0,
      [`${TERTIARY_CIRCLE_PREFIX}.unknown`]: data?.genderStats?.unknown || 0,
    };
    this.circleData['fact_sheet.lbl_second_circle_living_vs_dead_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.alive`]: data?.aliveStatusStats?.alive || 0,
      [`${TERTIARY_CIRCLE_PREFIX}.dead`]: data?.aliveStatusStats?.dead || 0,
      [`${TERTIARY_CIRCLE_PREFIX}.unknown`]: data?.aliveStatusStats?.unknown || 0,
    };
    this.circleData['fact_sheet.lbl_second_circle_relationship_status_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.single`]: data?.relationshipStatusStats?.single || 0,
      [`${TERTIARY_CIRCLE_PREFIX}.married`]: data?.relationshipStatusStats?.married || 0,
      [`${TERTIARY_CIRCLE_PREFIX}.divorced`]: data?.relationshipStatusStats?.divorced || 0,
    };
    this.circleData['fact_sheet.lbl_second_circle_lifespan_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.male`]: data?.lifespanStats?.male || 0.0,
      [`${TERTIARY_CIRCLE_PREFIX}.female`]: data?.lifespanStats?.female || 0.0
    };

    this.formatData(this.circleData)
  }

  formatData(circleData:any) {
    const lifespanData = circleData['fact_sheet.lbl_second_circle_lifespan_text'];

    if (lifespanData) {
      for (let key in lifespanData) {
        if (lifespanData.hasOwnProperty(key)) {
          lifespanData[key] = this.decimalPipe.transform(lifespanData[key], '1.1-1');
        }
      }
    }
  }

  calculateCirclePositions() {
    
    const { mainCircleRadius, secondaryCircleRadius } = this.getCircleRadii();
    const circleDistance = mainCircleRadius + secondaryCircleRadius + (this.config.base.mainCircle.gap); //gap was added to increase the distance between main and secondary circles

    const mainCircleCoordinates = this.getCircleCoordinates(this.config.base.mainCircle);

    const startingAngleDegrees = 270;  // starting angle was added so that the first element in circle data will be at the top
    const startingAngleRadians = startingAngleDegrees * (Math.PI / 180);

    const secondaryCircleLabels = Object.keys(this.circleData);
    const secondaryCircleCount = secondaryCircleLabels.length;

    for (let i = 0; i < secondaryCircleCount; i++) {

      const secondaryCircleLabel = secondaryCircleLabels[i]; // Get the category label 
      let tertiaryCircleData = this.circleData[secondaryCircleLabel]; // Data corresponding to the current category

      const angle = startingAngleRadians + (2 * Math.PI * i) / secondaryCircleCount; // Distribute evenly around the main circle
      
      const { secondaryCircleX, secondaryCircleY } = this.getSecondaryCircleCoordinates(mainCircleCoordinates, circleDistance, angle);
      const { lineStartX, lineStartY } = this.getLineStartCoordinates(mainCircleCoordinates, mainCircleRadius, angle);
       
      // Calculate the angle for the line (same as angle used for the secondary circle positioning)
      const lineAngle = (angle * (180 / Math.PI) + 90); // Convert from radians to degrees

      this.rightSide = this.determineSide(i, secondaryCircleCount, secondaryCircleX, mainCircleCoordinates.x);

      const tertiaryCirclesData = this.getTertiaryCircleData(tertiaryCircleData, this.rightSide);

      const secondaryLabelParts = this.translateHandler.translate(secondaryCircleLabels[i]).split(' ');

      this.circlePositions.push({
        secondaryCircleX,
        secondaryCircleY,
        secondaryCircleLabel: secondaryLabelParts,
        lineStartX,
        lineStartY,
        lineAngle,
        isRightSide: this.rightSide,
        tertiaryCircleImage: this.tertiaryCircleImage,
        tertiaryCirclesData
      });
    }
  }

  // Methods for calculations

  getCircleRadii() {
    const mainCircleRadius = this.config.base.mainCircle.height / 2;
    const secondaryCircleRadius = this.config.base.secondaryCircle.height / 2;
    return { mainCircleRadius, secondaryCircleRadius };
  }

  getCircleCoordinates(circle: any): { x: number, y: number } {
    return {
      x: circle.x + (circle.height / 2),
      y: circle.y + (circle.height / 2)
    };
  }

  getSecondaryCircleCoordinates(mainCircleCoordinates: { x: number, y: number }, circleDistance: number, angle: number): { secondaryCircleX: number, secondaryCircleY: number } {
    return {
      secondaryCircleX: mainCircleCoordinates.x + circleDistance * Math.cos(angle),
      secondaryCircleY: mainCircleCoordinates.y + circleDistance * Math.sin(angle)
    };
  }

  getLineStartCoordinates(mainCircleCoordinates: { x: number, y: number }, mainCircleRadius: number, angle: number): { lineStartX: number, lineStartY: number } {
    return {
      lineStartX: mainCircleCoordinates.x + mainCircleRadius * Math.cos(angle),
      lineStartY: mainCircleCoordinates.y + mainCircleRadius * Math.sin(angle)
    };
  }

  determineSide(index: number, totalCount: number, secondaryCircleX: number, mainCircleX: number): boolean {
    if (totalCount % 2 === 0) {
      if (index === 0) {
        return true;
      } else if (index === totalCount / 2) {
        return false;
      } else {
        return secondaryCircleX > mainCircleX;
      }
    }
    return false;
  }

  getTertiaryCircleData(tertiaryCircleData: any, isRightSide: boolean): any[] {
    const tertiaryCirclesData = [];
    let tertiaryCircleLabelXPosition;
    let tertiaryCircleLabelYPositions = [];
    let tertiaryCircleLabels = [];
    const tertiaryCircleCount = Object.keys(tertiaryCircleData).length;

    for (let tertiaryCircleCategory in tertiaryCircleData) {
      if (tertiaryCircleData.hasOwnProperty(tertiaryCircleCategory)) {
        this.tertiaryCircleImage = this.getTertiaryCircleImage(tertiaryCircleCount);
        if (tertiaryCircleCount === 3) {
          tertiaryCircleLabelYPositions = [-83, 8, 98]; //the y co-ordinates for text when there are 3 categories on the right side
          if(isRightSide){
            tertiaryCircleLabelXPosition = this.config.base.tertiaryText.offsetX1
          }else{
            tertiaryCircleLabelXPosition = this.config.base.tertiaryText.offsetX2
          }
        }
        if (tertiaryCircleCount === 2) {
          tertiaryCircleLabelYPositions = [-38,54]; //the y co-ordinates for text when there are 2 categories on the left side
          if(!isRightSide){
            tertiaryCircleLabelXPosition = this.config.base.tertiaryText.offsetX2
          }
        }
        tertiaryCircleLabels.push({
          key: this.translateHandler.translate(tertiaryCircleCategory),
          value: tertiaryCircleData[tertiaryCircleCategory]
        });
      }
    }

    tertiaryCirclesData.push({
      tertiaryCircleLabelXPosition,
      tertiaryCircleLabelYPositions,
      tertiaryCircleLabels
    });

    return tertiaryCirclesData;
  }

  getTertiaryCircleImage(tertiaryCircleCount: number): string {
    switch (tertiaryCircleCount) {
      case 2:
        return 'Con-2.svg';
      case 3:
        return 'Con-3.svg';
      case 4:
        return 'Con-4.svg';
      default:
        return 'Con-3.svg';
    }
  }

  onOptionsValueChanged(changedData: ReportOptions) {
    this.mapConfig(changedData);
    this.initReportData(this.config.options.title);
  }
}
