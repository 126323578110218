import { Injectable } from '@angular/core';
import { IndexedDbService } from './indexed-db.service';
import { Table } from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBHelper {
  constructor(private dbService: IndexedDbService) {}

  async isTableExist(tableName: string): Promise<boolean> {
    return await this.dbService.isTableExist(tableName);
  }

  async createTable(projectId: string): Promise<void> {
    await this.dbService.createTableForProject(projectId);
  }

  async saveData<T>(projectId: string, fetchData: () => Promise<T>,transformData?: (data: T) => T): Promise<void> {
    try {
      const table = await this.dbService.getTable<T>("individual_"+projectId);
      const data = await fetchData();
      if (data) {
        const finalData = transformData ? transformData(data) : data;
        if (Array.isArray(finalData)) {
          await table.bulkAdd(finalData);
        } else {
          await table.add(finalData);
        }        
      }
    } catch (error) {
      console.error(`Error saving data to table`, error);
    }
  }

  async getAll<T>(tableName: string): Promise<T[]> {
    try {
      const table = this.dbService.getTable<T>(tableName);
      return await (await table).toArray();
    } catch (error) {
      console.error(`Error fetching all data from table ${tableName}:`, error);
      throw error;
    }
  }

  async getTable<T>(tableName: string): Promise<Table<T>> {
    const table = this.dbService.getTable<T>(tableName);
    if (!table) {
      throw new Error(`Table "${tableName}" does not exist in the database.`);
    }
    return table;
  }

  async setCurrentProject(projectId: string): Promise<void> {
    await this.dbService.setCurrentProjectId(projectId);
  }

  async getCurrentProject(): Promise<string | null> {
    return await this.dbService.getCurrentProjectId();
  }
}
