import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../api-base.service';

@Injectable({
    providedIn: 'root'
})
export class TreeApiService {

    constructor(
        private apiBaseService: ApiBaseService,
    ) { }

    getDescendantList(individualId: number,generations:number): Observable<any> {
        return this.apiBaseService.get<any>("/api/tree/" + individualId + "/descendants/" + generations, null, null, null)
    }

    getTreeNodesByProject<T>() : Observable<T> {
        return this.apiBaseService.get("/api/tree/nodes", [], null);
    }
}