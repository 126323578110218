import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';

@Component({
  selector: 'app-search-main',
  templateUrl: './search-main.component.html',
  styleUrls: ['./search-main.component.scss']
})
export class SearchMainComponent  extends DialogBaseComponent implements OnInit  {

  public data: any;

  constructor(private ds: DialogService, private ref1: ChangeDetectorRef,) {
    super(ds, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {
    this.data.viewMode = mode;
  }
}
