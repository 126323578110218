import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FamilyProjectApiService {

  constructor(private apiBaseService: ApiBaseService) { }

  getFamilyProjects<T>(): Observable<T> {
    return this.apiBaseService.get("/api/familyProject", [], null);
  }

  createFamilyProject(data: any): Observable<any> {
    return this.apiBaseService.create('/api/familyProject', null, data);
  }

  setDefaultFamilyProject(data: any): Observable<any> {
    return this.apiBaseService.update('/api/familyProject/set-default', null, data);
  }

  deleteFamilyProject(familyProjectId: string): Observable<any> {
    return this.apiBaseService.delete('/api/familyProject/' + familyProjectId, null, null);
  }

  getFamilyProjectStats(familyProjectId: string): Observable<any> {
    return this.apiBaseService.get("/api/familyProject/familyprojectdetails/" + familyProjectId, [], null);
  }

  updateFamilyProject<T>(data: any): Observable<T> {
    return this.apiBaseService.update('/api/familyProject', null, data);
  }

  getFamilyStats(): Observable<any> {
    return this.apiBaseService.get("/api/familyProject/stats", [], null);
  }
}
