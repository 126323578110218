export const TERTIARY_CIRCLE_PREFIX = 'fact_sheet.lbl_tertiary_circle';

type CircleDataFields = {
  [key: string]: number;
};

export class CircleData {
  'fact_sheet.lbl_second_circle_age_text': CircleDataFields;
  'fact_sheet.lbl_second_circle_gender_text': CircleDataFields;
  'fact_sheet.lbl_second_circle_relationship_status_text': CircleDataFields;
  'fact_sheet.lbl_second_circle_marriage_age_text': CircleDataFields;
  'fact_sheet.lbl_second_circle_living_vs_dead_text': CircleDataFields;
  'fact_sheet.lbl_second_circle_lifespan_text': CircleDataFields;

  constructor() {
    this['fact_sheet.lbl_second_circle_age_text'] = {
      // [`${TERTIARY_CIRCLE_PREFIX}.oldest_male`]: 0,
      // [`${TERTIARY_CIRCLE_PREFIX}.oldest_female`]: 0,
      // [`${TERTIARY_CIRCLE_PREFIX}.youngest_male`]: 0,
      // [`${TERTIARY_CIRCLE_PREFIX}.youngest_female`]: 0,
    };
    this['fact_sheet.lbl_second_circle_gender_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.male`]: 0,
      [`${TERTIARY_CIRCLE_PREFIX}.female`]: 0,
      [`${TERTIARY_CIRCLE_PREFIX}.unknown`]: 0,
    };
    this['fact_sheet.lbl_second_circle_living_vs_dead_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.alive`]: 0,
      [`${TERTIARY_CIRCLE_PREFIX}.dead`]: 0,
      [`${TERTIARY_CIRCLE_PREFIX}.unknown`]: 0,
    };
    this['fact_sheet.lbl_second_circle_marriage_age_text'] = {
      // [`${TERTIARY_CIRCLE_PREFIX}.oldest_male`]: 0,
      // [`${TERTIARY_CIRCLE_PREFIX}.oldest_female`]: 0,
      // [`${TERTIARY_CIRCLE_PREFIX}.youngest_male`]: 0,
      // [`${TERTIARY_CIRCLE_PREFIX}.youngest_female`]: 0,
    };
    this['fact_sheet.lbl_second_circle_relationship_status_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.married`]: 0,
      [`${TERTIARY_CIRCLE_PREFIX}.single`]: 0,
      [`${TERTIARY_CIRCLE_PREFIX}.divorced`]: 0,
    };
    this['fact_sheet.lbl_second_circle_lifespan_text'] = {
      [`${TERTIARY_CIRCLE_PREFIX}.male`]: 0.0,
      [`${TERTIARY_CIRCLE_PREFIX}.female`]: 0.0
    };
  }
}