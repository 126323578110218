import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';
import { EditorMode, MultimediaTypes, NotifierEvents, PopupOpenMode, PopupViewMode, ReferenceType } from 'src/app/common/enums/enums';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { environment } from 'src/environments/environment';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { RootMember } from 'src/app/common/models/RootMember';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/common/models/UserModel';
import { Subscription } from 'rxjs';
import { ComponentBase } from '../../common/base/component-base';

@Component({
  selector: 'app-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss']
})



export class MenuMainComponent extends ComponentBase implements OnInit, OnDestroy {
  searchResults: { data: IndividualViewBase[], projectId: string } = { data: [], projectId: "" };
  expectedUpdates = [NotifierEvents.RootMemberChanged, NotifierEvents.RootMemberUpdated];
isHideSearchLoading = false;
userName = "";
userDataSubscription : Subscription
firstLetterOfName = "";

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private dialogService: DialogService,
    protected notifierService: NotifierV2Service,
    private individualAPIService: IndividualApiService,
    private projectRefService: ProjectRefService,
    private translateHandler: TranslateHandler,
    private translateService: TranslateService) {
    super(notifierService);
  }

  ngOnInit(): void {
    this.setUsername();
  }
 
  route(routeName){  
    
    let ref:EditorReferenceInfo = {id:0, isBreadcrumbUpdate:true};

    // Family editor
    if ( AppConstatnts.routerModes[routeName] == AppConstatnts.routerModes.FamilyEditor ){
      ref = {
        id : this.notifierService.getCurrentRootFamilyId(),   
        isBreadcrumbUpdate: true
      }
      this.notifierService.openEditor(EditorMode.FamilyEditor, ref);
      return;
    }
    if ( AppConstatnts.routerModes[routeName] == AppConstatnts.routerModes.IndividualEditor ){
      ref = {
        id : this.notifierService.getCurrentRootMemberId(),   
        isBreadcrumbUpdate: true
      }
      this.notifierService.openEditor(EditorMode.IndividualEditor, ref);
      return;
    }
    //Tree Editor, change @EM-10562
    if ( AppConstatnts.routerModes[routeName] == AppConstatnts.routerModes.TreeEditor ){
      this.router.navigate([AppConstatnts.routerModes[routeName], this.notifierService.getCurrentRootMemberId(), true])
      return;
    }
    this.router.navigate([AppConstatnts.routerModes[routeName]])
  }

  isDisabled()
  {
    //return true;
    return !this.projectRefService.isCurrentProjectEditable();
  }
  setUsername(){
    this.userDataSubscription = this.authenticationService.userDataSubject.subscribe((user: UserModel) => {
      this.userName = `${user.FirstName} ${user.LastName}`;      
      this.firstLetterOfName = this.getUserNameFirstLetter(user);
    });
  }

  getUserNameFirstLetter(user: UserModel): string {
    return user.FirstName ? user.FirstName.charAt(0).toUpperCase() : '';
}
  getUsername(){
    return this.userName;
  }

  getTitle(){
    return this.notifierService.getCurrentTitle();    
  }

  getVersion(){
    return environment.VERSION;
  }
  
  showPreferences(){    
      let popupInput = {
        FamilyProjectId: '0' ,
        ProjectName: "" ,
        viewMode: 2
      }
      this.dialogService.setHalf().open("PreferenceMain", popupInput).subscribe(res => {        
        if(res.data=="success"){
          window.location.href="#0";
        }
      })    
  }
  
  showAboutUs(){
    let popupInput = {
      viewMode: 1
    }
    this.dialogService.setHalf().open("AboutUs", popupInput).subscribe(res => {        
      console.log(res);
    })   
  }

  showShoppingCart(){    
     let url = environment.WEB_SHOP_MEMBERSHIP_URL.replace("{0}", this.authenticationService.getUser().CustomerId );
     this.openTab(url);
  }

  openTab(url:string){    
    window.open(url, "_blank");
  }

  getEnvironment(){
    return environment;
  }
  
  logout(){
    this.authenticationService.logout();
    window.location.href = environment.FT_PRODUCT_PAGE_URL;
  }

  search(ele){
    console.log(ele);
    console.log(ele.value);
  }

  notify(){
    this.refreshSearchResults();
  }

  refreshSearchResults(){
    if (!(this.searchResults?.projectId === this.projectRefService.getCurrentProject()?.familyProjectId)) 
      this.searchResults = { data: [], projectId: "" };
  }
   /** ------------------------ show lists -------------------------------------- */

  // Title map for list translation
  titleMap = new Map<string, string>(
    [
      ["Multimedia", "lbl_media_list"], // Todo: need translate
      ["Place",      "lbl_place_list"],
      ["Source",     "lbl_source_list"],
      ["IndividualMain", "lbl_persons_list"],
      ["ReportsMenu", "lbl_reports"],
    ]
  );
  
  // Show listing pops //Place//Multimedia//Individuals//Source
  openPopup(name: string) {
    
    //let projectName = this.filenameHelper.getProjectName(localStorage.getItem('fileName'));
    let projectName = this.projectRefService.getCurrentProject().projectName;

    let mainTitle = this.titleMap.get(name);
    var dialogSubscription = this.dialogService.setFull().open(name, {
      id: 0,
      reference: {
        id: 0,
        type: ReferenceType.None,
        rawData : ""
      },
      title       : this.translateHandler.translate('lbl_menu_list_title', [this.translateHandler.translate(mainTitle), projectName]) ?? "",
      mediaType   : [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO],
      viewMode    : PopupViewMode.GalleryView, // Gallery or editor view
      editMediaId : 0, // MediaId which supposes to be edited
      editSourceId: 0,
      isStandAlone: true,
      mode        : PopupOpenMode.List, // Todo: Make a enum
      showMode    : [1]
    }
    )
    .subscribe(response => {
      if (response !=null && response.data) {
        this.router.navigate([AppConstatnts.routerModes.TreeEditor]);
        console.log(">>>>>>>>>>>>>>")
      }  
    });
  }

  getFtHelpRoute(route:string){
    return `${route}?lang=${this.translateService.currentLang}`
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }
}
