<div class="reports-dialog">
  <div class="dialog-title-container">
    <div class="dialog-title-text">{{ "lbl_search" | translate }}</div>
  </div>
  <div class="tab-container app-dark-bg">            
      <div class="tabs">
        <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{ "lbl_member_search" | translate}}</a>
      </div>
    <div class="tab-forms app-light-bg">
      <app-member-search *ngIf="getViewMode() === 1"></app-member-search>         
    </div>            
  </div>
</div>