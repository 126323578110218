import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DialogService } from "src/app/services/UI/dialog.service";
import { DialogBaseComponent } from "../../dialog/dialog-base/dialog-base.component";
import { ProjectRefService } from "src/app/services/project-ref.service";
import { RootMember } from "src/app/common/models/RootMember";
import { NotifierV2Service } from "src/app/services/notifier-v2.service";
import { NotifierEvents } from "src/app/common/enums/enums";
import { IndividualIdbService } from "src/app/services/IndexedDb/individual-idb.service";


@Component({
  selector: "app-member-search",
  templateUrl: "./member-search.component.html",
  styleUrls: ["./member-search.component.scss"],
})
export class MemberSearchComponent extends DialogBaseComponent implements OnInit {
  isFilterDropDownOpen: boolean = false;
  searchPhrase: string = "";
  selectedMemberId: number | null = null;

  selectfilterText: string = "";
  selectedfilterType: number = null;
  selectfilterColumn: string = "";
  lastSelectfilterColumn: string = "";

  sortOptionList = [];
  individulaList = [];
  intIndividulaList = [];

  constructor(
    readonly dialogService: DialogService,
    readonly ref1: ChangeDetectorRef,
    readonly individualService: IndividualIdbService,
    readonly projectRefService: ProjectRefService,
    readonly notifierService: NotifierV2Service
  ) {
    super(dialogService, ref1);
  }

  ngOnInit(): void {
    this.getSortoptions();
    this.selectedfilterType = this.sortOptionList[0].id;
    this.selectfilterText = this.sortOptionList[0].name;
    this.selectfilterColumn = this.sortOptionList[0].columnName;
    this.lastSelectfilterColumn = this.sortOptionList[0].columnName;

    let familyProjectId = this.projectRefService.getCurrentProject()?.familyProjectId;
    this.individualService.getAll(familyProjectId).then((response) => {
      this.individulaList = response.filter( (individual) => individual.givenName ).sort((a, b) => a.givenName.localeCompare(b.givenName));
      this.intIndividulaList = response;
    });
  }

  getSortoptions() {
    this.sortOptionList = [
      { id: 1, name: "lbl_given_name_sort", columnName: "givenName" },
      { id: 2, name: "lbl_surname_sort", columnName: "surName" },
      { id: 3, name: "lbl_id_sort", columnName: "id" },
      { id: 4, name: "lbl_gender_sort", columnName: "gender" },
    ];
  }

  sortData(opt: number) {
    this.selectedMemberId = null;
    this.isFilterDropDownOpen = false;
    this.searchPhrase = '';

    let option = this.sortOptionList.find((item) => item.id === opt);  

    this.selectedfilterType = option.id;
    this.selectfilterText = option.name;
    this.selectfilterColumn = option.columnName;
    
    if (option.columnName === "gender") {
      this.columnSort(this.lastSelectfilterColumn);
    }else {
      this.columnSort(option.columnName);
      this.lastSelectfilterColumn = option.columnName;
    }   
  }

  columnSort(column: string) {
    this.individulaList = [...this.intIndividulaList].filter((individual) => individual[column]).sort((a, b) => {
      let valA = a[column];
      let valB = b[column];

      if (valA == null) return 1;
      if (valB == null) return -1;

      if (typeof valA === "number" && typeof valB === "number") {
        return valA - valB;
      }
      
      return String(valA).localeCompare(String(valB));
    });
  }

  showFilterDropdown() {
    this.isFilterDropDownOpen = !this.isFilterDropDownOpen;
  }

  searchOnKeyUp(searchText: string) {
    this.selectedMemberId = null;
    searchText = searchText.toLowerCase();

    if(searchText != "") {
      this.individulaList = this.intIndividulaList.filter((individual) => {
        return (
          individual.id.toString().toLowerCase().includes(searchText) ||
          individual.displayName.toLowerCase().includes(searchText) ||
          individual.givenName.toLowerCase().includes(searchText) ||
          individual.surName.toLowerCase().includes(searchText) ||
          individual.fullName.toLowerCase().includes(searchText)
        );
      });
    }else {
      this.sortData(this.selectedfilterType);
    }
  }

  selectMember(id: number) {
    this.selectedMemberId = id;
  }

  loadRootMember() {
    let selectedMember = this.individulaList.find((item) => item.id === this.selectedMemberId);

    var rootMember          = new RootMember();
    rootMember.DisplayName  = selectedMember.displayName;
    rootMember.FirstName    = selectedMember.displayName.substr(0,selectedMember.displayName.indexOf(' '));
    rootMember.LastName     = selectedMember.displayName.substr(selectedMember.displayName.indexOf(' ')+1);
    rootMember.Id           = selectedMember.id.toString();
    
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, rootMember);
    this.close();
  }

  cancel() {
    this.close();
  }
}
