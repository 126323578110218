import { Injectable } from '@angular/core';
import { IndexedDBHelper } from './indexed-db-helper';
import { StorageService } from './storage.service';
import { IndividualBaseViewModel } from 'src/app/common/models/IndividualViewModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndividualIdbService  extends StorageService<any> {
  private treeLevel = environment.TREE_LEVEL;
  
  constructor(indexedDBHelper: IndexedDBHelper) 
  {
    super(indexedDBHelper, 'individual');
  }

  public getTableName(projectId: string): string {
    return "individual_"+projectId;
  }

  async getIndividuals(tableName: string, rootMemberId: number): Promise<IndividualBaseViewModel[]> {
    try {
        const table = await this.indexedDBHelper.getTable<any>(tableName);
        if (!table) throw new Error(`Table for projectId: ${tableName} not found`);

        const individuals = await table.toArray();
        return this.processIndividuals(individuals, rootMemberId);
    } catch (error) {
        console.error('Error retrieving individuals:', error);
        throw error;
    }
  }

  private getParentId(parentId: number, familyRelationId: number | undefined): number {
      return this.getMinusOneIfZeroOrNull(
          parentId === 0 || parentId == null ? familyRelationId : parentId
      );
  }

  private transformIndividual(individual: any, displayIndex: number): any {
      const links = individual.links ? JSON.parse(individual.links) : {};
      return {
          id: individual.id,
          displayName: individual.displayName,
          gender: individual.gender,
          displayIndex: displayIndex,
          profileImage: individual.profileImageUrl,
          profileImageId: individual.profileImageId,
          parentFamilyId: individual.parentFamily?.Id ?? 0,
          spouseFamilyId: links.DefaultSpouseFamilyId || 0,
          fatherId: this.getParentId(links?.FatherId, individual.parentFamily?.husbandId),
          motherId: this.getParentId(links?.MotherId, individual.parentFamily?.wifeId),
          defaultFamilyId: links.DefaultParentFamilyId || 0,
      };
  }

  private addParentToView(individualParents: any[], parentId: number, displayIndex: number, viewIndividuals: any[]): void {
      const parent = individualParents.find(p => p.id === parentId);
      if (parent) {
          const transformedParent = this.transformIndividual(parent, displayIndex);
          viewIndividuals.push(transformedParent);
      }
  }

  public processIndividuals(individuals: any[], rootMemberId: number): any[] {
      try {
          const viewIndividuals: any[] = [];
          let currentDisplayIndex = 0;

          const rootMember = individuals.find(individual => String(individual.id) === String(rootMemberId));
          if (!rootMember) throw new Error(`Root member with id ${rootMemberId} not found`);

          viewIndividuals.push(this.transformIndividual(rootMember, currentDisplayIndex));

          for (let i = 0; i < this.treeLevel; i++) {
              currentDisplayIndex = i + 1;
              const individualsToMap = viewIndividuals.filter(ind => ind.displayIndex >= i && ind.displayIndex < currentDisplayIndex);

              const individualParentIds = [].concat(...individualsToMap.map(ind => [ind.fatherId, ind.motherId])).filter(id => id);
              const individualParents = individuals.filter(ind => individualParentIds.includes(ind.id));

              individualsToMap.forEach(individual => {
                  this.addParentToView(individualParents, individual.fatherId, this.GetDisplayIndex(individual.displayIndex, 0.0), viewIndividuals);
                  this.addParentToView(individualParents, individual.motherId, this.GetDisplayIndex(individual.displayIndex, 0.1), viewIndividuals);
              });
          }

          return viewIndividuals;
      } catch (error) {
          console.error('Error processing individuals:', error);
          throw error;
      }
  }

  private GetDisplayIndex(childDisplayIndex: number, increment: number): number {
    const divideBy = 10;  
    const newIndex = Math.floor(childDisplayIndex) + 1 + (((((childDisplayIndex * 100) % 100) * 2) / 10) / divideBy) + (increment / divideBy);
    return Math.round(newIndex * 100) / 100;  
  }

  private getMinusOneIfZeroOrNull(id: number | null | undefined): number {
    return id == null || id === 0 ? -1 : id;
  }
}