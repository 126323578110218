import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PreferencesService } from './services/preferences.service';
import { NotifierV2Service } from './services/notifier-v2.service';
import { ParentCommunicatorService } from './services/parent-communicator.service';
import { Subscription } from 'rxjs';
import { ParentMessageType } from './common/models/ParentMessage';
import { AuthenticationService } from './services/authentication.service';
import { EditorMode, NotifierEvents, StripeMessageActions } from './common/enums/enums';
import { AppConstatnts } from './common/constants/app-contstants';
import { MessageDialogService } from './components/common/message-dialog/services/message-dialog.service';
import { LoadingService } from './services/UI/loading.service';
import { Title } from '@angular/platform-browser';
import { ProjectRefService } from './services/project-ref.service';
import { MembershipService } from './services/membership.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  initialPageLoadedSubscription: Subscription;
  navigateToPageSubscription: Subscription;
  routeChangeSubscription: Subscription;
  initialAuthenticationSubscription: Subscription;
  routeChangeAuthenticationSubscription: Subscription;
  isAuthenticated: boolean = false;
  isUnsupportedSettings: boolean = false;
  acceptedResolutions : any;
  appDefaultCulture: string;

  loadingState:boolean = false;
  
  constructor(public translate: TranslateService,
    private preferenceService: PreferencesService,
    private notifierService: NotifierV2Service,
    private parentCommunicatorService: ParentCommunicatorService,
    private authenticationService: AuthenticationService,
    private messageDialogService: MessageDialogService,
    private loadingService : LoadingService,
    private projectRefService : ProjectRefService,
    private router: Router,
    private changeDetector : ChangeDetectorRef, // for expression already changed issue
    private titleService: Title,
    private membershipService: MembershipService,
    ) 
    {
      this.appDefaultCulture = environment.DEFAULT_CULTURE;
      this.acceptedResolutions = environment.ACCEPTED_RESOLUTION;      
    }
  

  ngOnInit(): void {
      // if the encEmail there pass it                  
      // Cant get query param from app component is angular ..wtf
      //https://stackoverflow.com/questions/54316263/unable-to-read-querystring-parameters-from-app-component
      const params = new URLSearchParams(window.location.search);

      this.loadingService.getState().subscribe( state =>{        
        this.loadingState = state;
      })
      
      this.loadApplication(params.get('encEmail'));
  }

   // for expression already changed issue
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  /**
   * Do application startup checks and start/init app
   * https://indepth.dev/posts/1287/rxjs-heads-up-topromise-is-being-deprecated
   * @param response 
   * @returns 
   */
  private async loadApplication(encEmail: string) {
    this.translate.addLangs(['en', 'nb', 'no']);
    await this.translate.use(this.appDefaultCulture).toPromise();
    this.titleService.setTitle(this.translate.instant("application.title"))

    await this.authenticationService.init();
    await this.membershipService.init();

    if (encEmail && !this.authenticationService.isAuthenticated1(encEmail)) {
      this.authenticationService.removeCookieToken();
      const authUrl = `${environment.FT_AUTH_URL}/authenticate/email?encEmail=${btoa(encEmail)}`;
      console.log(window.location.href);
      return window.open(authUrl, "_top");
  }
    else {
      if (!this.authenticationService.getCookieToken()) {
        this.messageDialogService.openError(
          this.translate.instant("Authentication.lbl_error"),
          this.translate.instant("Authentication.lbl_error_heading"),
          this.translate.instant("Authentication.lbl_error_login_again")
        ).toPromise();
        const embblaAuth_URL = environment.FT_AUTH_URL;
        window.open(embblaAuth_URL, "_top");
        return;
      }
    }

    const webShopUrl = environment.WEB_SHOP_MEMBERSHIP_URL.replace("{0}", this.authenticationService.getUser().CustomerId);
    if (this.membershipService.getMembershipExpirationObj().isNewUser) {
      await this.messageDialogService.openError(
        this.translate.instant("membership_check_error.lbl_title"),
        this.translate.instant("membership_check_error.lbl_info"),
        this.translate.instant("membership_check_error.lbl_prompt"))
        .toPromise();
        const emblaNoUrl = environment.EMBLA_V9_URL;
      window.open(emblaNoUrl, "_top");
      return;
    }

    if (!this.membershipService.getMembershipExpirationObj()) {
      await this.messageDialogService.openError(
        this.translate.instant("membership_check_error.lbl_title"),
        this.translate.instant("membership_check_error.lbl_info"),
        this.translate.instant("membership_check_error.lbl_prompt"))
        .toPromise();
      window.open(webShopUrl, "_top");
      return;
    }

    if (this.membershipService.isMembershipExpired()) {
      await this.messageDialogService.openError(
        this.translate.instant("expired_message.lbl_title"),
        this.translate.instant("expired_message.lbl_info"),
        this.translate.instant("expired_message.lbl_prompt"))
        .toPromise();
      window.open(webShopUrl, "_top");
      return;
    }

    // Check if browser supported
    if (!this.getCurrentBrowser().includes("chrome")) {
      const res = await this.messageDialogService.openUnSupportedBrowserWarning().toPromise();
      if (res == StripeMessageActions.Download) {
        window.open(environment.CHROME_DOWNLOAD_URL, "_top");
        return;
      }
    }

    // check resolution and show warning
    if (!this.isResolutionValid()) {
      const res = await this.messageDialogService.openUnSupportedResolutionWarning().toPromise();
    }

    this.router.navigate(["dashboard"]);
    /** --------------------------------- Project list loading and create new ------------------------------------ */
    // TODO: Project load related : Move to different method
    // Load all project - First time
    var data = await this.projectRefService.load().catch(() => {
      const res = this.messageDialogService.openError("Error", "Project load error", "The project list is not loaded, Contact support").subscribe(() => {
        return;
      });
    });

    // TODO: Need to set this - no idea where this is connected
    this.isAuthenticated = true;
    this.projectRefService.setCurrentProject(this.projectRefService.getCurrentProject()?.familyProjectId);
  }

  // Get current browser
  private getCurrentBrowser() {
    const browsers = [
      ['opera', 'OPR'],
      ['ie', 'Trident'],
      ['edge', 'Edg'],
      ['brave', 'Brave'],
      ['safari', 'Safari'],
      ['chrome', 'Chrome'],
      ['firefox', 'Firefox']
    ];

    for (const [browser, agent] of browsers) {
      if (navigator.userAgent.includes(agent)) {

        // Need to add this due to user agent contains both chrome and safari
        // Need to handle
        if (agent == "Chrome" || agent == "Safari") {
          if (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1
            && window.navigator.vendor == "Google Inc.") {
            return 'chrome';
          }
          else {
            return 'safari';
          }
        } else {
          return browser;
        }
      }
    }
    return null
  }


  //Check current resolution is support
  private isResolutionValid() { 
    return this.acceptedResolutions.includes((screen.width + "x" + screen.height));  
  }

  
 
  setInitialLoad(response: any, appDefaultCulture: any) {    
    this.preferenceService.load();
    //this.isAuthenticated = true;
    this.preferenceService.setCulture(appDefaultCulture);
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.mapRouteMember(response));
    this.parentCommunicatorService.send(ParentMessageType.ApplicationStarted, true);
    this.notifierService.setCurrentEditor(AppConstatnts.routerModes[response.routeInfo], this.mapRouteData(response), null);
  }

  setRouteChange(response: any) {
    this.notifierService.setCurrentEditor(AppConstatnts.routerModes[response.routeInfo], this.mapRouteData(response), null);
  }

  mapRouteMember(member: any = null) {
    if (member != null && member !== undefined) {
      return {
        Id: member.rootMember?.Id,
        DisplayName: member.rootMember?.DisplayName,
        FirstName: member.FirstName,
        LastName: member.LastName
      };
    }
  }

  mapRouteData(response) {
    if (response != null && response !== undefined) {
      return {
        id: response.rootMember?.Id,
        editorMode: response === 'TreeEditor'? EditorMode.TreeEditor: -1
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    if (this.initialPageLoadedSubscription) {
      this.initialPageLoadedSubscription.unsubscribe();
    }
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }

}
