import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  private readonly dbName = 'EmblaFT_DB';
  private db: Dexie;

  private tableSchema = '++id, givenName, fullName, surName';
  private projectSchema = 'key, value';

  constructor() {
    this.db = new Dexie(this.dbName);
  }

  async createTableForProject(tableName: string): Promise<void> {
    if (this.db.tables.some((table) => table.name === tableName)) {
      return;
    }
  
    try {
      this.closeConnection();
  
      const updatedSchema: { [key: string]: string } = {
        [tableName]: this.tableSchema,
        project: this.projectSchema,
      };
  
      const currentVersion = await this.getVersion();
      this.db.version(currentVersion + 1).stores(updatedSchema);
  
      await this.db.open();
    } catch (error) {
      console.error(`Error creating table ${tableName}:`, error);
    }
  }
  

  async isTableExist(tableName: string): Promise<boolean> {
    const dbExists = await Dexie.exists(this.dbName);
    if(dbExists){
      await this.openConnection();
    }
    return this.db.tables.some((table) => table.name === tableName);
  }

  async getTable<T>(tableName: string): Promise<Table<T, number>> {
    return this.db.table(tableName);
  }

  async openConnection(): Promise<void> {
    const dbExists = await Dexie.exists(this.dbName);
    if (dbExists && !this.db.isOpen()) {
      await this.db.open();
    }
  }

  closeConnection(): void {
    if (this.db.isOpen()) {
      this.db.close();
    }
  }

  async ensureConnection(): Promise<void> {
    if (!this.db.isOpen()) {
      await this.db.open();
    }
  }

  async getVersion(){
    return this.db.verno;
  }

  async setCurrentProjectId(projectId: string): Promise<void> {
    await this.db.table('project').put({ key: 'currentProjectId', value: projectId });
  }

  async getCurrentProjectId(): Promise<string | null> {
    const record = await this.db.table('project').get('currentProjectId');
    return record ? record.value : null;
  }
}
