import { IndividualBaseViewModel } from 'src/app/common/models/IndividualViewModel';
import { IndexedDBHelper } from './indexed-db-helper';
import { IStorageService } from './istorage.service';

export abstract class StorageService<T> implements IStorageService<T> {
  constructor(protected indexedDBHelper: IndexedDBHelper, protected tableName: string) {}
  // private is_indexed_db_enabled = environment.ENABLE_INDEXED_DB;

  async checkTableExists(tableName: string): Promise<boolean> {
    return await this.indexedDBHelper.isTableExist(tableName);
  }

  async createTable(tableName: string): Promise<void> {
    await this.indexedDBHelper.createTable(tableName);
  }

  async saveData(projectId: string, data?: T): Promise<void> {
    await this.indexedDBHelper.saveData(projectId, () => Promise.resolve(data));
  }

  async getById(): Promise<T | null> {
    throw new Error('Method not implemented.');
  }

  async getAny(): Promise<T | null> {
    throw new Error('Method not implemented.');
  }

  async getAll(familyProjectId?: string): Promise<T[] | null> {
    try {
      const data = await this.indexedDBHelper.getAll<T>("individual_" + familyProjectId);
      if (data && data.length > 0) {
        console.log(`Fetched ${data.length} records from ${this.tableName}.`);
        return data;
      } else {
        console.log(`No data found in ${this.tableName}.`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching data from ${this.tableName}:`, error);
      return null;
    }
  }

  async update(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async delete(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  count(filter?: T): Promise<number> {
    throw new Error('Method not implemented.');
  }
  // public abstract getFromAPI(id?: string): Promise<T | null>;
  public abstract getTableName(projectId : string):string

}
